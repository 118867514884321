import React from 'react';
import { ReactSVG } from 'react-svg';

import sanitiseString from '../../utils/sanitiseString';

const SanitiseSVG = ({ logo, name, logoClass }) => {
  const nameID = `svg-${sanitiseString(name)}`;

  return (
    <ReactSVG
      src={logo}
      beforeInjection={(svg) => {
        const styleEl = svg.querySelector('style');

        let styleInner = styleEl ? styleEl.textContent : null;

        for (let step = 500; step >= 0; step--) {
          const clEl = svg.querySelectorAll(`.cl${step}`);
          if (clEl.length) {
            clEl.forEach((el) => {
              el.classList.add(`${nameID}-cl${step}`);
              el.classList.remove(`cl${step}`);
            });
          }

          const stEl = svg.querySelectorAll(`.st${step}`);
          if (stEl.length) {
            stEl.forEach((el) => {
              el.classList.add(`${nameID}-st${step}`);
              el.classList.remove(`st${step}`);
            });
          }

          const clsEl = svg.querySelectorAll(`.cls-${step}`);
          if (clsEl.length) {
            clsEl.forEach((el) => {
              el.classList.add(`${nameID}-cls-${step}`);
              el.classList.remove(`cls-${step}`);
            });
          }

          if (step === 0 && styleEl) {
            /* eslint-disable no-useless-escape */
            styleInner = styleInner.replace(
              new RegExp(`\.cl`, 'gi'),
              `.${nameID}-cl`
            );
            styleInner = styleInner.replace(
              new RegExp(`\.st`, 'gi'),
              `.${nameID}-st`
            );
            /* eslint-enable no-useless-escape */

            styleEl.textContent = styleInner;
          }
        }

        svg.setAttribute('title', `${name} logo`);
        svg.setAttribute('id', nameID);
        svg.setAttribute('data-name', nameID);
        svg.classList.add('sanitise-svg');
        svg.classList.add(logoClass);
      }}
    />
  );
};

export default SanitiseSVG;
