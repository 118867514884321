import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import classnames from 'classnames';

import FadeUpIntoView from '../FadeUpIntoView';
import SanitiseSVG from '../SanitiseSVG/SanitiseSVG';

const ClientLogosGrid = ({ page }) => {
  const data = useStaticQuery(graphql`
    query ClientLogosGridQuery {
      content: contentfulClientLogosGrid {
        title
        logos: clientLogos {
          file {
            src: url
            details {
              image {
                height
                width
              }
            }
          }
          title
        }
      }
    }
  `);

  const {
    content: { title, logos },
  } = data;

  return (
    <section
      className={classnames('panel', 'panel--inset', 'client-logos-grid', {
        'panel--inset': page !== 'home',
        [`client-logos-grid--${page}`]: page,
      })}
    >
      <header className="client-logos-grid__header">
        <h2 className="client-logos-grid__title">{title}</h2>
      </header>
      <ol className="client-logos-grid__grid">
        {logos.map(
          (
            {
              file: {
                src,
                details: {
                  image: { width, height },
                },
              },
              title,
            },
            i
          ) => (
            <li
              className="client-logos-grid__item"
              key={`clientLogosGrid-${i}`}
            >
              <FadeUpIntoView
                extraClass={classnames('client-logos-grid__logo-wrap', {
                  'client-logos-grid__logo-wrap--landscape':
                    width / height > 3.5,
                })}
              >
                <SanitiseSVG
                  logo={src}
                  name={title}
                  logoClass="client-logos-grid__logo"
                />
              </FadeUpIntoView>
            </li>
          )
        )}
      </ol>
    </section>
  );
};

export default ClientLogosGrid;
