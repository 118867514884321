import React from 'react';
import classnames from 'classnames';

const Spacer = ({ variant }) => (
  <div
    className={classnames('spacer', {
      [`spacer--${variant}`]: variant,
    })}
  />
);

export default Spacer;
