import React, { memo } from 'react';
import classnames from 'classnames';

import LinkButton from '../LinkButton';
import FadeUpIntoView from '../FadeUpIntoView';

const DescriptorPanel = ({ title, link, linkLabel, home }) => (
  <section
    className={classnames('panel', 'panel--inset', 'descriptor-panel', {
      'descriptor-panel--non-home': !home,
    })}
  >
    <FadeUpIntoView>
      <h2 className="descriptor-panel__title">{title}</h2>
    </FadeUpIntoView>

    <div className="descriptor-panel__button-wrap">
      <LinkButton
        text={linkLabel}
        link={link}
        extraClass="descriptor-panel__button"
        hasArrow={true}
      />
    </div>
  </section>
);

export default memo(DescriptorPanel);
