import React, { useState, memo } from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import classnames from 'classnames';

import { useInView } from 'react-hook-inview';
import { useScrollDirection } from 'react-use-scroll-direction';

const SwipeUpImage = ({ image, extraClass }) => {
  const [inView, setInView] = useState(false);

  let ref;

  [ref] = useInView({
    threshold: 0.1,
    unobserveOnEnter: true,
    onEnter: () => {
      setInView(true);
    },
  });

  const { isScrollingUp } = useScrollDirection();

  return (
    <div
      className={classnames('swipe-up-image', {
        [extraClass]: extraClass,
      })}
      ref={ref}
    >
      <div
        className={classnames('swipe-up-image__inner', {
          'swipe-up-image__inner--in-view': inView,
          'swipe-up-image__inner--force': isScrollingUp,
        })}
        ref={ref}
      >
        <Img
          fluid={image.fluid}
          fadeIn={false}
          loading="eager"
          alt={image.alt}
          className="swipe-up-image__image"
        />
      </div>
    </div>
  );
};

export default memo(SwipeUpImage);
