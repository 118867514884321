import React, { memo, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { clamp } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { useInView } from 'react-hook-inview';

import LinkButton from '../LinkButton';
import HorizontalSliderPanel from '../HorizontalSliderPanel';

const HorizontalSlider = ({ panels, link }) => {
  const [inView, setInView] = useState(false);
  const [isStuck, setIsStuck] = useState(false);
  const [isAbsolute, setIsAbsolute] = useState(false);
  const [overallHeight, setOverallHeight] = useState(0);
  const [panelsPercent, setPanelsPercent] = useState(1);
  const [panelWidth, setPanelWidth] = useState(0);
  const [panelShown, setPanelShown] = useState(0);
  const [indicatedPanel, setIndicatedPanel] = useState(0);

  const isMobile = useMediaQuery({ query: '(max-width: 550px)' }, undefined);

  let viewRef;
  const outerElRef = useRef(null);
  const innerElRef = useRef(null);
  const panelElRef = useRef(null);

  const panelCount = panels.length;

  [viewRef] = useInView({
    threshold: 0,
    unobserveOnEnter: false,
    onEnter: () => setInView(true),
    onLeave: () => setInView(false),
  });

  useEffect(() => {
    if (inView) {
      handleResize();

      if (window) {
        window.addEventListener('resize', handleResize);
        document.addEventListener('scroll', checkState);

        return () => {
          window.removeEventListener('resize', handleResize);
          document.removeEventListener('scroll', checkState);
        };
      }
    } else {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('scroll', checkState);
    }
  }, [inView]);

  useEffect(() => {
    setIndicatedPanel(panelShown);
  }, [panelShown]);

  useEffect(() => {
    setOverallHeight(isMobile ? panelCount * 100 : panelCount * 150);
  }, [isMobile, panelCount]);

  const handleResize = () => {
    const panelEl = panelElRef.current;
    setPanelWidth(panelEl.getBoundingClientRect().width);

    checkState();
  };

  const checkState = () => {
    const outerEl = outerElRef.current;
    const innerEl = innerElRef.current;

    const { height: innerHeight } = innerEl.getBoundingClientRect();
    const { top: topOffset, height: totalHeight } =
      outerEl.getBoundingClientRect();

    const bottomThreshold = topOffset * -1 - totalHeight + innerHeight;
    const atBottom = bottomThreshold >= 0;

    const scrollThreshold = 5;

    if (topOffset <= 0) {
      setIsStuck(true);

      const distanceTravelled = clamp(topOffset * -1, 0, totalHeight);
      const percentScrolled = clamp(
        (distanceTravelled / totalHeight) * 100,
        0,
        100
      );

      const shouldScrollPanel =
        percentScrolled > scrollThreshold &&
        percentScrolled <= 100 - scrollThreshold;

      const newScrollPercentage = clamp(
        ((percentScrolled - scrollThreshold) / (100 - scrollThreshold * 2)) *
          100,
        0,
        100
      );

      const shownPanel = clamp(
        Math.ceil(newScrollPercentage / (100 / panelCount)),
        1,
        panelCount
      );

      setPanelShown(shownPanel);

      if (!atBottom && shouldScrollPanel) {
        setPanelsPercent(newScrollPercentage);
      }
    } else if (isStuck || topOffset > 0) {
      setIsStuck(false);
      setPanelsPercent(0);
    }

    setIsAbsolute(atBottom);
  };

  const rawTranslateModifier =
    (-1 * ((100 / panelCount) * (panelCount - 1))) / 100;

  const translateModifier = isMobile
    ? rawTranslateModifier * 1.45
    : rawTranslateModifier * 1.24;

  const indicatorWidth = 100 / panelCount;

  return (
    <div ref={viewRef}>
      <section
        className="horizontal-slider"
        ref={outerElRef}
        style={{ height: `${overallHeight}vh` }}
      >
        <div
          className={classnames('horizontal-slider__sticker', {
            'horizontal-slider__sticker--stuck': isStuck,
            'horizontal-slider__sticker--absolute': isAbsolute,
          })}
          ref={innerElRef}
        >
          <div
            className={classnames('horizontal-slider__indicator', {
              'horizontal-slider__indicator--visible': isStuck || isAbsolute,
            })}
          >
            <span
              className="horizontal-slider__indicator-tag"
              style={{
                width: `${indicatorWidth}%`,
                transform: `translateX(${(indicatedPanel - 1) * 100}%)`,
              }}
            ></span>
          </div>
          <ul
            className="horizontal-slider__list"
            style={{
              minWidth: `${(panelWidth * panelCount) / 10}rem`,
              transform: `translateX(${panelsPercent * translateModifier}%)`,
            }}
          >
            {panels.map(({ title, text }, i) => (
              <li
                className="horizontal-slider__list-item"
                key={`HorizontalSliderPanel-${i}`}
                ref={panelElRef}
              >
                <HorizontalSliderPanel {...{ title, text, inView }} />
              </li>
            ))}
          </ul>
          <LinkButton
            text={link.label}
            link={link.url}
            extraClass="horizontal-slider__button"
            hasArrow={true}
          />
        </div>
      </section>
    </div>
  );
};

export default memo(HorizontalSlider);
