import React, { useEffect, useRef, useState, memo } from 'react';
import classnames from 'classnames';

import getFormattedText from '../../utils/getFormattedText';

const HorizontalSliderPanel = ({ title, text, inView }) => {
  const [showTitle, setShowTitle] = useState(false);
  const [showText, setShowText] = useState(false);
  const titleThreshold = useRef(0);
  const textThreshold = useRef(0);

  const thisEl = useRef(null);

  useEffect(() => {
    if (window) {
      if (inView) {
        handleResize();

        document.addEventListener('scroll', handleScroll);
        document.addEventListener('resize', handleResize);

        return () => {
          document.removeEventListener('scroll', handleScroll);
          document.removeEventListener('resize', handleResize);
        };
      } else {
        document.removeEventListener('scroll', handleScroll);
        document.removeEventListener('resize', handleResize);
      }
    }
  }, [inView]);

  const handleScroll = () => {
    const leftPosition = thisEl.current.getBoundingClientRect().left;

    setShowTitle(leftPosition <= titleThreshold.current);
    setShowText(leftPosition <= textThreshold.current);
  };

  const handleResize = () => {
    const windowWidthPercent = window.innerWidth / 100;

    titleThreshold.current = windowWidthPercent * 60;
    textThreshold.current = windowWidthPercent * 25;
  };

  return (
    <article
      ref={thisEl}
      className="horizontal-slider-panel panel panel--inset"
    >
      <div className="horizontal-slider-panel__inner">
        <h2
          className={classnames('horizontal-slider-panel__title', {
            'horizontal-slider-panel__title--shown': showTitle,
          })}
        >
          {getFormattedText(title)}
        </h2>
        <p
          className={classnames('horizontal-slider-panel__text', {
            'horizontal-slider-panel__text--shown': showText,
          })}
        >
          {text.text}
        </p>
      </div>
    </article>
  );
};

export default memo(HorizontalSliderPanel);
